@import "../../variables.scss";

.table {
    width: 100%;
    table-layout: fixed;

    table {
        width: 100%;
        height: 100%;
    }

    .tbl-header {
        //background-color: rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        background-color: lightgrey;

        .hide-empty-sort {
            width: 10px;
            height: 10px;

            svg {
                display: none !important;
            }
        }

        &.spirli-color {
            background-color: coral;
        }

        .delete-header svg {
            height: 0.7em;
        }
    }

    .tbl-content {
        max-height: calc(100vh - 170px);
        overflow-x: auto;
        overflow-y: auto;
        margin-top: 0px;

        //border: 1px solid rgba(5,5,5,0.3);
        tr {
            border-style: solid;
            border-width: 1px 0 1px 0;
            border-color: transparent;
            position: relative;
            cursor: pointer;

            .emptytable {
                text-align: center;
                &:before {
                    display: none;
                }
            }
        }

        tr:nth-child(even) {
            background-color: #f1f1f1;

            &.spirli-color {
                background-color: rgb(255, 243, 68);
            }
        }

        tr:nth-child(odd) {
            background-color: #afafaf;

            &.spirli-color {
                background-color: rgb(6, 251, 255);
            }
        }

        tr.selected:nth-child(even) {
            // background-color: rgb(196, 200, 255);

            td:after {
                // background-image: linear-gradient(to right, rgba(196, 200, 255, 0) 0%, rgba(196, 200, 255, 1) 100%);
            }
        }

        tr.selected {
            background-color: rgb(226, 228, 255);

            td:after {
                // background-image: linear-gradient(to right, rgba(226, 228, 255, 0) 0%, rgba(226, 228, 255, 1) 100%);
            }
        }

        td.rawjsoncell {
            border-left: #444;
            border-style: solid;
            max-width: 10px !important;
        }

        .delete-taglog {
            height: 0.6em;
            color: $primary-color;
        }

        tr.active td:last-child:after {
            content: "";
            width: 8px;
            height: 8px;
            background-color: $primary-color;
            position: absolute;
            right: 10px;
            border-radius: 10px;
            top: calc(50% - 4px);
        }

        tr:hover,
        tr.selected:hover,
        tr.active:hover {
            background-color: rgb(254, 222, 222);
        }
    }

    .headercontents {
        display: flex;
        max-height: 10px;
        cursor: default;

        p {
            bottom: 15px;
            position: relative;
        }

        .sortableHeader {
            cursor: pointer;
        }

        .sortableHeader:hover {
            text-decoration: underline;
        }

        svg {
            cursor: pointer;
        }

        .sort-possible,
        .sort-up,
        .sort-down {
            display: flex;
        }

        .sort-possible svg {
            font-size: 13px;
            bottom: 0.1em;
            margin-left: 5px;
            opacity: 0.7;
        }
    }

    .sortdirection {
        float: right;
        position: relative;
        bottom: 0.3em;
    }

    tr {
        height: 40px;
    }

    th,
    td {
        padding: 7px 7px;
        text-align: left;
        color: #444;
        position: relative;
    }

    th {
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;

        input {
            width: 10px;
        }
    }

    td {
        vertical-align: middle;
        font-weight: 300;
        font-size: 14px;
        //border-bottom: solid 1px rgba(5,5,5,0.1);
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 10px;
            //background: red;
            // background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        }

        > span {
            position: absolute;
            left: 5px;
            right: 0;
            top: 0;
            bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
        }

        &:before {
            content: "&nbsp;";
            visibility: hidden;
        }

        &.select-toggle {
            width: 18px;
            height: 26px;
            display: inline-block;
            padding: 7px 5px;

            span {
                width: 20px;
                margin: -5px 0 0 0;
            }

            &:before {
                position: absolute;
                content: none;
            }

            &:after {
                display: none;
            }
        }

        &.detail-toggle,
        &.detail-view-fist-td {
            &:before {
                position: absolute;
                content: none;
            }
        }
    }

    tr:nth-child(even) td:after {
        // background-image: linear-gradient(to right, rgba(241, 241, 241, 0) 0%, rgba(241, 241, 241, 1) 100%);
    }

    tr:hover,
    tr.selected:hover,
    tr.active:hover,
    tr.selected:nth-child(even):hover {
        td:after {
            // background-image: linear-gradient(to right, rgba(254, 222, 222, 0) 0%, rgba(254, 222, 222, 1) 100%);
        }
    }

    tr.detail-view td {
        &:before {
            position: absolute;
            content: none;
        }
    }

    tr.detail-view td,
    td.detail-toggle.open {
        &:after {
            background-image: none;
        }
    }

    .no-all-select {
        width: 20px;
        display: block;
    }

    .detail-toggle {
        cursor: pointer;
    }

    .detail-toggle,
    .detail-header {
        width: 15px;
        display: block;

        svg {
            font-size: 14px;
            color: #333;
        }
    }

    .detail-toggle.open,
    .detail-view-fist-td {
        background-color: $primary-color;
        margin: -1px 0;
        padding: 8px 5px;

        svg {
            color: #fff;
        }
    }
}

@media (max-width: 1000px) {
    .table {
        .tbl-content {
            max-height: calc(100vh - 300px);
        }
    }
}

@media (max-width: 666px) {
    .table {
        width: 100%;
        margin: 0 0px;

        .tbl-content {
            height: 60%;
            overflow-y: scroll;

            table {
                padding-bottom: 40px;
            }
        }

        tr {
            height: 50px;
        }

        th,
        td {
            padding: 7px 10px;
        }
    }
}

@media (max-width: 700px) {
    .hideinmobile {
        display: none !important;
    }

    .select-toggle {
        display: none !important;
    }

    .showinmobile.col-3 {
        width: 33%;
    }

    .showinmobile.col-4 {
        width: 25%;
    }

    .hide-sort {
        display: none;
    }

    tbody {
        td {
            &.showinmobile {
                border-right-style: solid;
                border-right-width: 0.5px;
                border-right-color: rgb(98, 98, 98);
            }
        }
    }
}
