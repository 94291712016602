.einteilung-view-head {
  width: 100%;
  background-color: lightgrey;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative; }
  .einteilung-view-head div,
  .einteilung-view-head span {
    padding-left: 15px; }

.add-additional-position {
  display: flex;
  position: relative;
  bottom: 90px;
  right: 70px;
  float: right; }

.sectioncontainer {
  padding: 2.5vh;
  overflow: scroll;
  max-height: 90vh; }
  .sectioncontainer .clubposition {
    margin-top: 15px; }
    .sectioncontainer .clubposition:hover {
      font-weight: bold; }
    .sectioncontainer .clubposition .obligatory-toggle {
      display: flex; }
      .sectioncontainer .clubposition .obligatory-toggle p {
        margin: 0;
        margin-left: 10px;
        margin-top: 5px; }

.rightsection .draggable {
  margin-top: 10px; }

.rightsection .all-personal-list-wrp {
  max-height: 85%;
  height: 85%;
  overflow-y: hidden;
  transition: height 0.3s;
}

.rightsection .all-personal-list-wrp.open {
  max-height: 50%;
  height: 50%;
  overflow-y: hidden;
  transition: height 0.3s;
}

.rightsection .blocked-list-wrp {
    height: 40%;
    max-height: 40%;
    overflow-y: hidden;
    transition: height 0.3s;
}

.rightsection .blocked-list-wrp.collapsed {
  height: 50px;
  max-height: 50px;
  overflow-y: hidden;
  transition: height 0.3s;
}

.rightsection .blocked-list {
  max-height: 80%;
  overflow-y: auto;
}
.rightsection .all-personal-list {
        height: 50%;
    }

.rightsection .namelist {
  max-height: 30%;
  overflow-x: scroll;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-top-style: solid;
  border-top-width: 0.5px; }

.color-ok {
  background-color: green; }

.color-warn {
  background-color: darkred; }

.color-info {
  background-color: #f0d800; }

.enteredNameList p {
  width: 250px;
  padding: 2px;
  background-color: lightgrey; }

.other-days-wrp {
  position: relative;
  float: left;
  right: 10px; }
  .other-days-wrp .warn {
    color: darkred; }
  .other-days-wrp .info {
    color: green; }
  .other-days-wrp svg {
    margin-top: 4px;
    height: 18px; }

.warning-day {
  font-weight: bold;
  color: red; }

.salary-so-far {
  position: relative;
  float: right;
  right: 10px; }
  .salary-so-far .status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    float: right;
    position: relative;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 5px; }

.openslot {
  background-color: rgba(211, 211, 211, 0.767);
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  height: 30px;
  width: 300px; }

.draggable {
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
  width: 300px;
  cursor: grab;
  background-color: lightgrey; }

.bulk-open {
  bottom: 100px; }

.bulk-close {
  bottom: 170px; }

.bulk-draft {
  bottom: 240px; }

.droppable {
  min-height: 65vh;
  max-height: 100%;
  overflow-x: auto; }

.dragging {
  background-color: lightgreen;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px; }
