input {
  width: 300px; }

input.not-ok {
  border-width: 3px;
  border-color: #d10000; }

span.not-ok {
  font-size: 10px;
  color: white; }

span.ok {
  display: none; }

.profile-view {
  padding: 20px; }

.club-newsselect {
  display: flex; }
  .club-newsselect span {
    display: inline-block; }

.my-clubs {
  color: white; }
  .my-clubs h3 {
    margin-top: 5px !important;
    margin-bottom: 15px !important;
    height: 15px;
    color: white;
    font-size: larger; }
  .my-clubs ul {
    list-style: none; }
    .my-clubs ul li {
      margin-top: 10px; }

.my-meta {
  color: white; }
  .my-meta .detail-view-item {
    margin-left: 20px; }
    .my-meta .detail-view-item p {
      width: 80%;
      margin-left: 20px;
      margin-bottom: 5px; }
    .my-meta .detail-view-item .label {
      font-size: small;
      margin-left: 20px;
      opacity: 0.7; }

.toggle-line {
  color: white; }
  .toggle-line:hover {
    color: black; }
  .toggle-line .detail-input-item {
    margin-left: 20px; }
    .toggle-line .detail-input-item input {
      margin-bottom: 3px;
      width: 70vw; }
    .toggle-line .detail-input-item .label {
      width: 100px; }

.my-reminders {
  color: white; }
  .my-reminders input[type=time] {
    padding: 5px 10px;
    font-size: larger; }
  .my-reminders h3 {
    height: 15px;
    color: white;
    font-size: larger; }
  .my-reminders .reminder-type-subviewlist {
    padding: 0;
    display: flex; }
    .my-reminders .reminder-type-subviewlist li {
      width: 30px; }
    .my-reminders .reminder-type-subviewlist .medium-width {
      width: 50px; }
    .my-reminders .reminder-type-subviewlist .wider-li {
      width: 150px; }
  .my-reminders ul {
    list-style: none; }
    .my-reminders ul li {
      margin-top: 10px; }
  .my-reminders .selectablehours-container {
    display: flex;
    padding-bottom: 10px; }
    .my-reminders .selectablehours-container .selectablehours {
      width: 25px;
      height: fit-content;
      cursor: pointer;
      border-width: 0.5px;
      border-radius: 4px;
      border-style: solid;
      border-color: whitesmoke;
      margin-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 8px;
      padding-right: 8px; }
      .my-reminders .selectablehours-container .selectablehours.selected {
        background-color: whitesmoke;
        color: black; }
