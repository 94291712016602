.newsbutton {
  position: absolute;
  top: 10px;
  right: 50px;
  cursor: pointer; }

.shake {
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
  color: #aa0000; }

@keyframes shake-animation {
  0% {
    transform: translate(0, 0); }
  1.78571% {
    transform: translate(5px, 0); }
  3.57143% {
    transform: translate(0, 0); }
  5.35714% {
    transform: translate(5px, 0); }
  7.14286% {
    transform: translate(0, 0); }
  8.92857% {
    transform: translate(5px, 0); }
  10.71429% {
    transform: translate(0, 0); }
  100% {
    transform: translate(0, 0); } }

.pbhidden {
  display: none; }

.newsitems {
  display: block;
  position: absolute;
  right: 9px;
  top: 9px;
  min-height: 45px;
  min-width: 300px;
  border-radius: 0 0 0 3px;
  overflow: hidden;
  background-color: #039be5;
  z-index: 100; }
  .newsitems .versionitem,
  .newsitems .newsitem {
    cursor: pointer;
    display: flex;
    position: relative;
    min-height: 45px;
    min-width: 150px;
    border-radius: 0 0 0 3px;
    overflow: hidden;
    background-color: #039be5;
    z-index: 100;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: initial;
    border-top: 1px solid rgba(255, 255, 255, 0.3); }
    .newsitems .versionitem:hover,
    .newsitems .newsitem:hover {
      background-color: lightblue; }
    .newsitems .versionitem .news_content,
    .newsitems .versionitem .news_date,
    .newsitems .newsitem .news_content,
    .newsitems .newsitem .news_date {
      display: block;
      padding: 10px 15px;
      font-size: 13px;
      color: #fff;
      text-decoration: none; }
    .newsitems .versionitem .news_content_version,
    .newsitems .newsitem .news_content_version {
      display: block;
      padding: 6px 0px 0px 10px;
      font-size: 15px;
      color: #fff;
      text-decoration: none; }
    .newsitems .versionitem .news_content_list,
    .newsitems .newsitem .news_content_list {
      display: block;
      padding: 0px 15px 6px;
      font-size: 13px;
      color: #fff;
      text-decoration: none; }
      .newsitems .versionitem .news_content_list .news_content_item,
      .newsitems .newsitem .news_content_list .news_content_item {
        display: block;
        padding-top: 5px; }
    .newsitems .versionitem .news_date,
    .newsitems .newsitem .news_date {
      position: absolute;
      right: 0; }
  .newsitems .versionitem {
    display: block; }
    .newsitems .versionitem h4 {
      margin-left: 10px;
      margin-top: 0px;
      color: white; }
