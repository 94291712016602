.einteilung-content {
  height: 55vh;
  overflow-y: scroll; }
  .einteilung-content table {
    padding: 10px;
    background-color: lightgray; }
    .einteilung-content table tbody {
      overflow-x: scroll; }
    .einteilung-content table tr:nth-child(even) {
      background-color: #f1f1f1; }
    .einteilung-content table tr:nth-child(odd) {
      background-color: #afafaf; }
    .einteilung-content table tr {
      border-style: solid;
      border-width: 1px 0 1px 0;
      border-color: transparent;
      position: relative;
      cursor: pointer; }
      .einteilung-content table tr.needed {
        background-color: lightcoral; }
      .einteilung-content table tr td {
        padding: 7px 7px;
        text-align: left;
        color: #444;
        position: relative;
        font-weight: 300;
        font-size: 14px;
        width: 300px; }
        .einteilung-content table tr td.time-td {
          width: 55px; }
          .einteilung-content table tr td.time-td input {
            width: 60px;
            height: 30px;
            margin: 0;
            padding: 0px; }
        .einteilung-content table tr td .removable-name span {
          max-width: 80%;
          overflow-x: hidden;
          float: left; }
        .einteilung-content table tr td .removable-name .remove-icon {
          float: right;
          height: 15px; }
          .einteilung-content table tr td .removable-name .remove-icon svg {
            height: 20px; }
        .einteilung-content table tr td .choose-available select {
          width: 150px;
          height: 30px;
          margin: 0;
          padding: 0px; }
      .einteilung-content table tr:hover {
        background-color: lightblue; }

.export-pdf {
  margin-top: 20px; }

.available-h4 {
  margin-bottom: 0;
  padding-bottom: 5px; }

.einteilung-available {
  border-top-style: solid;
  border-top-width: 0.5px;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  max-height: 12vh;
  overflow: scroll;
  padding: 10px; }

.printbutton {
  position: relative;
  float: right;
  top: -50px;
  right: 30px; }
  .printbutton svg {
    height: 35px;
    width: auto; }

.einteilung-multi-wrp {
  padding: 10px;
  display: flex; }
  .einteilung-multi-wrp .einteilung-multi {
    padding-left: 10px;
    padding-right: 10px;
    max-height: 90vh;
    overflow: scroll; }
    .einteilung-multi-wrp .einteilung-multi span {
      position: absolute;
      padding: 5px;
      background-color: lightgrey;
      z-index: 1; }
      .einteilung-multi-wrp .einteilung-multi span.parting-1 {
        width: 64.25%; }
      .einteilung-multi-wrp .einteilung-multi span.parting-2 {
        width: 45%; }
      .einteilung-multi-wrp .einteilung-multi span.parting-3 {
        width: 30%; }
      .einteilung-multi-wrp .einteilung-multi span.parting-4 {
        width: 25%; }
    .einteilung-multi-wrp .einteilung-multi.parting-1 {
      width: 100%; }
    .einteilung-multi-wrp .einteilung-multi.parting-2 {
      width: 50%; }
    .einteilung-multi-wrp .einteilung-multi.parting-3 {
      width: 33%; }
    .einteilung-multi-wrp .einteilung-multi.parting-4 {
      width: 25%; }
    .einteilung-multi-wrp .einteilung-multi table {
      margin-top: 20px;
      padding: 10px;
      background-color: lightgray; }
      .einteilung-multi-wrp .einteilung-multi table tbody {
        overflow-x: scroll; }
      .einteilung-multi-wrp .einteilung-multi table tr:nth-child(even) {
        background-color: #f1f1f1; }
      .einteilung-multi-wrp .einteilung-multi table tr:nth-child(odd) {
        background-color: #afafaf; }
      .einteilung-multi-wrp .einteilung-multi table tr {
        border-style: solid;
        border-width: 1px 0 1px 0;
        border-color: transparent;
        position: relative;
        cursor: pointer; }
        .einteilung-multi-wrp .einteilung-multi table tr.needed {
          background-color: lightcoral; }
        .einteilung-multi-wrp .einteilung-multi table tr td {
          padding: 7px 7px;
          text-align: left;
          color: #444;
          position: relative;
          font-weight: 300;
          font-size: 14px;
          width: 300px; }
        .einteilung-multi-wrp .einteilung-multi table tr:hover {
          background-color: lightblue; }
