.login-wrapper {
  background-color: #525252;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }
  .login-wrapper .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh; }
    .login-wrapper .login input {
      padding-bottom: 8px;
      font-size: 0.6em;
      margin-bottom: 8px; }
    .login-wrapper .login button {
      margin-top: 5px;
      font-size: 0.6em;
      width: 80px;
      height: 30px; }
    .login-wrapper .login footer {
      position: absolute;
      bottom: 10px;
      font-size: small; }
      .login-wrapper .login footer a {
        font-size: small; }
    .login-wrapper .login a {
      font-size: 0.5em; }
    .login-wrapper .login .error-msg {
      color: #d40000;
      outline: white;
      font-size: small; }
