.section-head {
  padding: 10px;
  height: 90px; }

.section-content {
  padding: 10px;
  height: calc(85% - 80px);
  overflow-y: auto;
  max-height: 73vh;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: grey; }

.section-footer {
  position: absolute;
  height: 40px;
  padding: 10px;
  bottom: 0px;
  display: flex; }
  .section-footer .formbutton {
    margin-right: 10px;
    background-color: #008dce;
    color: white;
    cursor: pointer;
    height: 40px;
    width: 150px;
    text-align: center;
    line-height: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: lightslategray; }
    .section-footer .formbutton.delete {
      background-color: #af0000; }
      .section-footer .formbutton.delete:hover {
        background-color: #ff5252; }
    .section-footer .formbutton:hover {
      background-color: lightblue; }
  .section-footer .save {
    right: 10px;
    position: absolute; }
