.card {
    display: grid;
    padding-bottom: 0px;

    &.dark {
        color: black;

        .card-label,
        .card-content {
            color: black;
        }
    }

    .card-label {
        color: rgba(245, 245, 245, 0.733);
        font-size: small;
    }

    .card-content {
        height: 30px;
        color: white;
        font-size: larger;
    }

    &.input {
        input {
            height: 35px;
            font-size: medium;
            padding: 5px;
        }

        .card-label {
            margin-top: 20px;
        }
    }
}
