.enter {
  background-color: #038109; }

.remove {
  background-color: #8d0000; }

.warn-closed {
  background-color: #8d0000;
  height: fit-content; }

.enter-item {
  display: flex;
  background-color: #c5c5c5;
  color: #313131;
  cursor: pointer;
  width: 240px;
  text-align: center;
  line-height: 40px;
  border-width: 1px;
  border-style: none;
  margin-bottom: 30px;
  padding-left: 15px; }
  .enter-item .admin-einteilung-manage-item {
    width: 190px;
    height: 60px; }
    .enter-item .admin-einteilung-manage-item .admin-einteilung-manage-title {
      min-width: 60px;
      float: left;
      text-align: left; }
    .enter-item .admin-einteilung-manage-item .admin-einteilung-manage-subtitle {
      min-width: 60px;
      float: left;
      text-align: left;
      margin-top: -20px; }
  .enter-item svg {
    height: 60px;
    width: 35px;
    float: right; }
    .enter-item svg:hover {
      color: #8f0000; }
  .enter-item:hover {
    background-color: lightgray; }
