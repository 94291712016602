.header-datepicker {
  height: 40px;
  background-color: lightgrey;
  position: absolute;
  top: 55px;
  margin-left: 60px; }
  .header-datepicker .react-datepicker-wrapper {
    width: 100px; }
    .header-datepicker .react-datepicker-wrapper input {
      width: 100px;
      height: 30px;
      padding: 8px; }
  .header-datepicker .date-confirm {
    left: 20px;
    top: 10px;
    width: 60px;
    float: right;
    position: relative;
    cursor: pointer;
    border-width: 0.5px;
    border-radius: 5%;
    border-style: solid;
    text-align: center;
    line-height: 25px; }
    .header-datepicker .date-confirm:hover {
      background-color: white; }
  .header-datepicker .filter-button {
    position: relative;
    top: 10px;
    float: right;
    right: 20px;
    cursor: pointer;
    width: 80px;
    height: 30px;
    border-width: 0.5px;
    border-radius: 5%;
    border-style: solid;
    text-align: center;
    line-height: 30px; }
    .header-datepicker .filter-button:hover {
      background-color: #ebebeb; }

@media (max-width: 666px) {
  .header-datepicker {
    top: 55px;
    margin-left: 10px; }
    .header-datepicker .react-datepicker-wrapper {
      width: 85px; }
      .header-datepicker .react-datepicker-wrapper input {
        width: 85px;
        height: 35px; }
    .header-datepicker .date-confirm {
      left: 10px;
      top: 10px;
      width: 40px;
      float: right;
      position: relative;
      cursor: pointer;
      border-width: 0.5px;
      border-radius: 5%;
      border-style: solid;
      text-align: center;
      line-height: 30px; }
      .header-datepicker .date-confirm:hover {
        background-color: white; } }
