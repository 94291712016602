.searchbar {
    width: 100%;
    height: 40px;
    background-color: lightgray;

    input {
        height: 20px;
        width: 300px;
        padding-left: 10px;
        padding-top: 1px;
        padding-bottom: 1px;
        margin-left: 30px;
        margin-top: 5px;
    }
}

.deletequestion {
    margin-top: 20vh;
    padding: 10%;

    p {
        text-align: center;
    }

    .formbutton {
        margin: 0 auto;
    }
}

.form-input-elem {
    display: flex;
    flex-direction: column;
}

.toggle-line {
    display: block;
    cursor: pointer;

    &:hover {
        border-width: 0.2px;
        border-style: solid;
        border-color: gray;
        background-color: rgb(233, 233, 233);
        border-radius: 5px;
    }

    span {
        position: relative;
        top: -5px;
    }

    .details-list {
        list-style: none;

        li {
            display: inline;

            &:hover {
                font-weight: bold;
            }

            .details-title {
                float: left;
                width: 180px;
            }

            .details-content {
                float: left;
                width: 180px;
            }
        }
    }
}
