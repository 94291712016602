.sidemenu {
    position: fixed;
    z-index: 10;
    height: calc(100% - 45px);
    width: 50px;
    left: 0px;
    padding-top: 0%;
    // overflow: hidden;
    background-color: lightgrey;
    border-right: 0.5px;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color: white;
    &.spirli-color {
        background-color: chartreuse;
    }
    .sidemenu-title {
        font-size: small;
        width: 8vw;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .side-button {
        display: flex;
        background-color: gray;
        color: white;
        cursor: pointer;
        height: 40px;
        width: 50px;
        text-align: center;
        line-height: 40px;
        border-width: 1px;
        border-style: none;
        overflow: hidden;
        margin-bottom: 30px;

        &.active {
            background-color: #008dce;
        }

        transition: width 0.3s;

        @media screen and (min-width: 1000px) {
            &:hover {
                background-color: rgb(0, 170, 226);
                width: 200px;

                svg {
                    width: 53px;
                    height: 28px;
                    margin-top: 3px;
                }
            }
        }

        svg {
            width: 50px;
            height: 25px;
            margin-top: 5px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .sidemenu {
        height: calc(100% - 45px);
    }
    .sidemenu-title {
        display: none;
    }

    .side-button {
        width: 12vw;
        margin: 0 auto;
    }
}
