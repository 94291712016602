:root {
    --color-primary: #039be5;
    --color-hover: lightblue;
    --color-dark-bg: #525252;
    --color-light-bg: lightgray;
    --color-text-light: #FFFFFF;
    --color-text-dark: #000000;
}

$primary-color: var(--color-primary);
$primary-color-hover: var(--color-hover);
$primary-color-dark-bg: var(--color-dark-bg);
$primary-color-light-bg: var(--color-light-bg);
$primary-color-text-light: var(--color-text-light);
$primary-color-text-dark: var(--color-text-dark);
