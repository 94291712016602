.profilebutton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer; }

.pbhidden {
  display: none; }

.buttons {
  display: block;
  position: absolute;
  right: 9px;
  top: 9px;
  min-height: 45px;
  min-width: 150px;
  border-radius: 0 0 0 3px;
  overflow: hidden;
  background-color: #039be5;
  z-index: 100; }
  .buttons .profiledrop {
    padding: 0;
    margin: 0; }
  .buttons .realname {
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: initial; }
    .buttons .realname span {
      display: block;
      padding: 10px 15px;
      font-size: 15px;
      font-weight: bolder;
      color: #fff;
      text-decoration: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
  .buttons ul {
    list-style: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer; }
    .buttons ul li {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: initial; }
      .buttons ul li:hover {
        background-color: lightblue; }
      .buttons ul li span {
        display: block;
        padding: 10px 15px;
        font-size: 13px;
        color: #fff;
        text-decoration: none;
        border-top: 1px solid rgba(255, 255, 255, 0.3); }
