@import "./variables.scss";

body {
    overflow: hidden;
    background-color: #525252;
}

#root {
    max-height: 100%;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    background-color: #525252;
}

.spirli-color {
    background-color: rgb(82, 224, 0);
}

.apphead {
    display: block;
    height: 45px;
    padding-top: 10px;
    background-color: white;

    .version-no {
        padding-top: 1px;
        padding-bottom: 1px;
        padding-left: 4px;
        padding-right: 4px;
        background-color: white;
        border-color: darkblue;
        border-style: solid;
        border-width: 0.5px;
        border-radius: 10%;
        z-index: 100;
        transform: rotate(20deg);
        position: absolute;
        left: 160px;
        top: 14px;
        font-size: x-small;
    }

    .apphead-title {
        position: absolute;
        left: 20px;
        top: 15px;
        font-size: larger;
    }
}

.logout-button {
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
}

.action-icon {
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: $primary-color;
    color: $primary-color-text-light;
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: flex;
    -webkit-transition: width 0.4s;
    /* For Safari 3.1 to 6.0 */
    transition: width 0.4s;

    svg {
        height: 100%;
        width: 40px;
        padding-left: 5px;
        padding-right: 10px;
    }

    span {
        position: relative;
        top: 15px;
        overflow: hidden;
        width: fit-content;
        white-space: nowrap;
    }

    &:hover {
        width: 200px;
    }

    &.disabled {
        opacity: 0.5;
        background-color: black;
    }
}

.button {
    background-color: #039be5;
    color: white;
    cursor: pointer;
    height: 40px;
    width: 200px;
    text-align: center;
    line-height: 40px;
    border-width: 1px;
    border-style: none;

    margin-bottom: 30px;

    &:hover {
        background-color: lightblue;
    }
}

.content {
    background-color: #525252;
    // min-height: 93vh;
    // max-height: 93vh;
    float: right;
    width: 100%;
    height: calc(100% - 45px);
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .main-content {
        z-index: 0;
        width: calc(100% - 50px);
        height: 100%;
        padding-left: 50px;
    }

    .leftsection {
        background-color: lightgrey;
        height: calc(95% - 40px);
        width: calc(50% + 55px);
        .tbl-content {
            width: 100%;
        }
    }
    .content .leftsection .tbl-content {
        width: calc(50% + 30px);
    }
    // display: flex;
    // float: left;

    .rightsection {
        background-color: lightgrey;
        display: block !important;
        height: calc(95% - 6px);
        position: absolute;
        top: 55px;
        right: 0;
        width: calc(50% - 80px);
        display: flex;
        float: right;
        border-left: 1px solid #ddd;
        box-shadow: -15px 0 15px -15px;

        .section-head {
            border-bottom: darkgray;
            border-style: solid;
            border-top: 0;
            border-left: 0;
            border-right: 0;
        }

        .action-bar {
            &.bottom {
                position: absolute;
                bottom: 0;
                width: 100%;
                // padding: 15px;
            }
        }
    }
}

.highlight-blue,
.highlight-green,
.highlight-yellow,
.highlight-gray {
    border-left-width: 3px;
    border-left-style: solid;
    padding-left: 5px;
}

.highlight-blue {
    border-color: blue;
}

.highlight-green {
    border-color: green;
}

.highlight-yellow {
    border-color: yellow;
}

.highlight-gray {
    border-color: gray;
}

.hide {
    display: none;
}

@media screen and (min-width: 701px) {
    .hideindesktop {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .hideinmobile {
        display: none;
    }

    #root {
        max-height: 99%;
        height: 99%;
        min-height: 99%;
        overflow: hidden;
        background-color: #525252;
    }

    .content {
        width: 100%;
        // min-height: 93%;
        // max-height: 93%;
    }

    .rightsection {
        width: calc(100% - 50px) !important;
        top: 55px !important;
        right: auto !important;
    }

    .formbutton {
        width: 35vw;
    }
}

.formbutton {
    margin-right: 10px;
    background-color: #008dce;
    color: white;
    cursor: pointer;
    height: 40px;
    width: 150px;
    text-align: center;
    line-height: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: lightslategray;
    border-radius: 3px;
    transition: background-color 0.2s;

    &.delete {
        background-color: #af0000;

        &:hover {
            background-color: rgb(255, 82, 82);
        }
    }

    &:hover {
        background-color: lightblue;
    }
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="time"],
input[type="mail"],
select {
    width: 300px;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

textarea {
    width: 300px;
    max-width: 300px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
