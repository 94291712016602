.header-filter {
  height: 40px;
  background-color: lightgrey; }
  .header-filter .filter-button {
    position: relative;
    top: 10px;
    float: right;
    right: 20px;
    cursor: pointer;
    width: 80px;
    height: 25px;
    border-width: 0.5px;
    border-radius: 5%;
    border-style: solid;
    text-align: center;
    line-height: 25px; }
    .header-filter .filter-button:hover {
      background-color: #ebebeb; }
  .header-filter .activefilters {
    position: absolute;
    right: 12%;
    margin-top: 10px;
    display: flex; }
    .header-filter .activefilters .activefilter-elem {
      border-color: black;
      border-width: 0.5px;
      border-style: solid;
      width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
      height: 25px; }
      .header-filter .activefilters .activefilter-elem span {
        bottom: 5px;
        left: 10px;
        position: relative; }

.leftsection .filter-dialog {
  right: 50vw; }

.filter-dialog {
  position: absolute;
  background-color: white;
  width: 350px;
  height: 250px;
  z-index: 10;
  right: 30vw;
  display: flex;
  cursor: pointer;
  border-width: 1px;
  border-style: groove;
  display: block; }
  .filter-dialog p {
    margin: 0;
    height: 30px;
    width: 350px;
    line-height: 40px;
    text-align: center; }
  .filter-dialog .filter-area {
    position: relative;
    width: 350px;
    left: 0; }
    .filter-dialog .filter-area .filter-checkboxes {
      width: 300px;
      position: relative;
      left: 30px;
      display: flex; }
      .filter-dialog .filter-area .filter-checkboxes .checkbox-wrapper {
        width: 50px !important; }
        .filter-dialog .filter-area .filter-checkboxes .checkbox-wrapper input {
          width: 20px;
          height: 20px; }
        .filter-dialog .filter-area .filter-checkboxes .checkbox-wrapper input[type=checkbox].css-checkbox {
          position: absolute;
          z-index: -1000;
          left: -1000px;
          overflow: hidden;
          clip: rect(0 0 0 0);
          height: 1px;
          width: 1px;
          margin: -1px;
          padding: 0;
          border: 0; }
        .filter-dialog .filter-area .filter-checkboxes .checkbox-wrapper input[type=checkbox].css-checkbox + label.css-label {
          padding-left: 26px;
          height: 21px;
          display: inline-block;
          line-height: 21px;
          background-repeat: no-repeat;
          background-position: 0 0;
          font-size: 21px;
          vertical-align: middle;
          cursor: pointer; }
        .filter-dialog .filter-area .filter-checkboxes .checkbox-wrapper input[type=checkbox].css-checkbox:checked + label.css-label {
          background-position: 0 -21px; }
        .filter-dialog .filter-area .filter-checkboxes .checkbox-wrapper label.css-label {
          background-image: url(http://csscheckbox.com/checkboxes/u/csscheckbox_f8d40671eec752c2a075981314745ffa.png);
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none; }
  .filter-dialog .dialog-buttons {
    background-color: #039be5;
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 350px;
    display: flex; }
    .filter-dialog .dialog-buttons .dialog-button {
      height: 30px;
      width: 175px;
      line-height: 30px;
      text-align: center; }
      .filter-dialog .dialog-buttons .dialog-button.single {
        width: 350px; }
      .filter-dialog .dialog-buttons .dialog-button:hover {
        background-color: #39bdff; }
  .filter-dialog.small {
    height: 250px; }
  .filter-dialog.medium {
    height: 350px; }
  .filter-dialog.large {
    height: 450px; }

@media (max-width: 666px) {
  .header-filter {
    height: 90px; }
    .header-filter .filter-button {
      width: 50px; }
  .filter-dialog {
    width: 60vw;
    right: unset; }
    .filter-dialog .filter-area {
      position: relative;
      width: 70vw;
      left: 0; }
    .filter-dialog .dialog-buttons {
      background-color: #039be5;
      position: absolute;
      bottom: 0;
      height: 30px;
      width: 60vw;
      display: flex; } }

@media (max-width: 930px) {
  .header-filter {
    height: 90px;
  align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around; }
        .header-filter .filter-wrp {
            padding-top: 15px;
        }
        .header-filter .filter-wrp .filter-button {
           padding-left: 15px;
           padding-right: 15px;
        }
    .header-filter .activefilters {
      font-size: smaller;
      position: absolute;
      right: 15%;
      margin-top: 50px;
      display: flex; }
      .header-filter .activefilters .activefilter-elem {
        border-color: black;
        border-width: 0.5px;
        border-style: solid;
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px; }
        .header-filter .activefilters .activefilter-elem span {
          bottom: 5px;
          left: 10px;
          position: relative; } }
